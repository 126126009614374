import React, { useState } from 'react'
import { Link } from "gatsby"
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useAppState } from '../context'
import UpdateSubscriptionSteps from './UpdateSubscriptionSteps'
import Line from '../../images/line.svg'

const stripePromise = loadStripe(
  process.env.GATSBY_STRIPE_KEY,
  { locale: 'sv' }
)

export default function UpdateSubscriptionPage() {
  const { cartStatus } = useAppState();
  const [step, setStep] = useState(1)
  const [isCreditCard, setIsCreditCard] = useState(true)

  return (
    <div className="order update-subscription">
      <Elements stripe={stripePromise}>
        {/* Kolla om användaren redan har ett abonnemang "Ändra stegen"*/}
        <ul className="steps">
          <li onClick={() => step !== 3 && setStep(1)} className={`step`}>Ändra licenser</li>
          <li className={`step ${step < 2 && 'disabled'}`}>
            <img src={Line} alt="line" />
            Sammanställning
          </li>
          <li className={`step ${step < 3 && 'disabled'}`}>
            <img src={Line} alt="line" />
            Klart!
          </li>
        </ul>

        {/* Checkout steps */}
        <div>
          <UpdateSubscriptionSteps step={step} setStep={(value) => setStep(value)} />
          {cartStatus.status === 'cart-error' && <span className="cart-error">Något gick fel i val av licens. Försök igen.</span>}
        </div>
  
          <div className="footer">
            {/* Kolla om användaren redan har ett abonnemang "Ändra stegen" */}
            {step === 1 || step === 3
              ? <Link to="/user"><span className="step-back">Tillbaka</span></Link>
              : <a role="button" href="/subscription" onClick={(e) => {
                e.preventDefault();
                if (step === 2) {
                  localStorage.removeItem('woo-session')
                  setStep(step - 1)
                }
                else if (step === 4 && !isCreditCard) {
                  setIsCreditCard(true)
                }
                else {
                  setStep(step - 1)
                }
              }} className="step-back">
                Tillbaka
              </a>
            }
          </div>
      </Elements>
    </div>
  )
}

