import React from 'react'
import { Router } from "@reach/router"
import Layout from '../../components/Layout'
import UpdateSubscriptionPage from '../../components/subscription/UpdateSubscriptionPage'
import PrivateRoute from '../../components/privateRoute'
import CancelSubscription from '../../components/subscription/CancelSubscription'
import { useAppState } from '../../components/context'
import { navigate } from 'gatsby-link'
import ReactivateSubscription from '../../components/subscription/ReactivateSubscription'
import CookieBar from "../../components/CookieBar";

export default function Subscription() {
  const isBrowser = () => typeof window !== "undefined"
  const { customerData } = useAppState();
  return (
    <div className="shop">
      <Layout>
        <Router>
          {/* <PrivateRoute path="/subscription" component={UpdateSubscriptionPage}/> */}
          {customerData &&
            customerData?.subscription?.subscriptionType !== "NONE" &&
            customerData?.subscription?.subscriptionType !== "TRIAL" ?
            <>
              <PrivateRoute path="/subscription/change" component={UpdateSubscriptionPage} />
              <PrivateRoute path="/subscription/cancel" component={CancelSubscription} />
              <PrivateRoute path="/subscription/reactivate" component={ReactivateSubscription} />
            </>
            : isBrowser() && customerData && navigate("/user")
          }
        </Router>
        {/* <CookieBar /> */}
      </Layout>
    </div>
  )
}

