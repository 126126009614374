import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { gql, useMutation } from '@apollo/client'
import ClipLoader from 'react-spinners/ClipLoader'
import { useCustomerQuery } from '../../hooks/queries/useCustomerQuery'
import dayjs from 'dayjs'

export default function ReactivateSubscription() {

  const { getCustomer, data: customer } = useCustomerQuery();
  const [activateStatus, setActivateStatus] = useState()
  const [activateSubscription] = useMutation(ACTIVATE_SUBSCRIPTION, { variables: { input: { status: 'ACTIVE' } } })
  
  const reactivate = (e) => {
    e.preventDefault();
    setActivateStatus('resolving')
    activateSubscription().then(res => {
      console.log(res);
      setActivateStatus('resolved');
      getCustomer();
    }).catch(err => {
      console.log(err);
      setActivateStatus('error')
    })
  }

  return (
    <div className="reactivate-subscription">

      {activateStatus !== 'resolved' &&
        <>
          <h2>Vill du återuppta abonnemang?</h2>
          <div className="d-flex">
            <button disabled={activateStatus === 'resolving'}  className="secondary" onClick={() => navigate("/user")}>Avbryt</button>
            <button disabled={activateStatus === 'resolving'}  onClick={reactivate}>Återuppta</button>
          </div>
          <div className="messages">
            {activateStatus === 'resolving' &&
              <div className="text-center spinner">
                <p>Återupptar abonnemang</p>
                <ClipLoader size={22} color={"#ffffffbb"} />
              </div>
            }
            {activateStatus === 'error' && (
              <div className="error-notice text-center">
                <p>Kunde inte återuppta abonnemang, försök igen eller kontakta support</p>
              </div>
            )}
          </div>
        </>
      }

      {activateStatus === 'resolved' &&
        <div className="reactivated">
          <h2>Tack för att du vill fortsätta använda Pausit!</h2>
          <p>Nästa debitering sker {dayjs(customer?.subscription.nextPaymentDate).get('date').toString()}/{(dayjs(customer?.subscription.nextPaymentDate).get('month') + 1).toString()}-{dayjs(customer?.subscription.nextPaymentDate).get('year').toString().substr(-2)}</p>
          
          <button onClick={() => navigate("/user")}>Till mina sidor</button>
        </div>
      }
    </div>
  )
}

const ACTIVATE_SUBSCRIPTION = gql`
  mutation($input: ChangeUserSubscriptionInput!) {
    changeUserSubscription(input: $input) {
      mutationStatus
    }
  }
`
