import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { gql, useMutation } from '@apollo/client'
import ClipLoader from 'react-spinners/ClipLoader'
import { useCustomerQuery } from '../../hooks/queries/useCustomerQuery'
import dayjs from 'dayjs'

const CANCEL_SUBSCRIPTION = gql`
  mutation($input: ChangeUserSubscriptionInput!) {
    changeUserSubscription(input: $input) {
      mutationStatus
    }
  }
`
export default function CancelSubscription() {

  const [cancelStatus, setCancelStatus] = useState('')
  const {getCustomer, data: customer} = useCustomerQuery();

  const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION, { variables: { input: { status: 'CANCELLED' } } })
  const cancel = (e) => {
    e.preventDefault();
    setCancelStatus('resolving')
    cancelSubscription().then(res => {
      console.log(res);
      setCancelStatus('resolved');
      getCustomer();
    }).catch(err => {
      console.log(err);
      setCancelStatus('error')
    })
  }

  return (
    <div className="cancel-subscription">
      {cancelStatus !== 'resolved' &&
        <>
          <h2>Vill du avsluta ditt abonnemang?</h2>
          <div className="d-flex">
            <button disabled={cancelStatus === 'resolving'} className="secondary" onClick={() => navigate("/user")}>Avbryt</button>
            <button disabled={cancelStatus === 'resolving'} onClick={cancel}>Avsluta</button>
          </div>

          <div className="messages">
            {cancelStatus === 'resolving' &&
              <div className="text-center spinner">
                <p>Avslutar abonnemang</p>
                <ClipLoader size={22} color={"#ffffffbb"} />
              </div>
            }

            {cancelStatus === 'error' && (
              <div className="error-notice text-center">
                <p>Kunde inte avsluta abonnemang, försök igen eller kontakta support</p>
              </div>
            )}
          </div>
        </>
      }
      {cancelStatus === 'resolved' &&
        <div className="canceled">
          <h2>Ditt abonnemang är avslutat</h2>
          <div>
            <p>Du kan fortsätta använda Pausit till <strong>{dayjs(customer?.subscription?.endDate).get('year').toString()}-{(dayjs(customer?.subscription.endDate).get('month') + 1).toString()}-{dayjs(customer?.subscription.endDate).get('date').toString()}.</strong></p>
            <p>Se till att pausgympa in i det sista!</p>
          </div>
          <button onClick={() => navigate("/user")}>Till mina sidor</button>
        </div>
      }
    </div>
  )
}

