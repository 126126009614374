import React, { useState } from 'react'
import { gql } from '@apollo/client'
import UpdateLicense from './UpdateLicense'
import UpdateOrder from './UpdateOrder'
import { useAppState } from '../context'

export default function UpdateSubscriptionSteps({ step, setStep }) {

  const {customerData} = useAppState();
  // License
  const [license, setLicense] = useState({
    type: "",
    quantity: customerData?.subscription?.license?.total,
    price: 100
  })

  return (
    <div className="order-steps">
      {/* Kolla om användaren redan har ett abonnemang */}
      {step === 1 &&
        <UpdateLicense customer={customerData} license={license} setLicense={(value) => { setLicense(value) }} setStep={(value) => { setStep(value) }}/>
      }

      {step === 2 || step === 3 ?
        <UpdateOrder customerData={customerData} license={license} step={step} setStep={(value) => setStep(value)}
        />
        : ''
      }
    </div>
  )
}


