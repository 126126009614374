import React, { useState } from 'react'
import { useAppState } from '../context'
import { gql, useMutation } from '@apollo/client'
import ClipLoader from 'react-spinners/ClipLoader'
import UpdateCompleted from './UpdateCompleted'
import { Link } from 'gatsby'
import { useGoogleAnalytics } from "../../hooks/useGoogleAnalytics"

export default function UpdateOrder({ customerData, license, step, setStep }) {

  const { cartRenewal } = useAppState();
  const [renewalStatus, setRenewalStatus] = useState('')

  const { getCid, trackingId } = useGoogleAnalytics()

  const [kbCheckout] = useMutation(CHECKOUT, {
    onCompleted({ kbCheckout }) {
      console.log(kbCheckout);
      setRenewalStatus('resolved')
      setStep(3)
    },
    onError(error) {
      console.log(error);
      setRenewalStatus('error')
    }
  })

  const submit = async (e) => {
    e.preventDefault();
    setRenewalStatus('resolving')
    const clientId = await getCid()
    await kbCheckout({
      variables: {
        input: {
          clientMutationId: "12345",
          billing: {
            email: customerData?.email,
          },
          useStoredUserData: true,
          gaCID: clientId,
          gaTID: trackingId,
          isSwitching: true,
        },
      },
    })
      .then(res => {
        console.log(res.data)
      })
      .catch(err => {
        setRenewalStatus("error")
        console.log(err)
      })
  }

  return (
    <div className="complete-order update-order">
      {step === 3
        ? <UpdateCompleted license={license} customerData={customerData} />
        : <form onSubmit={(e) => submit(e)}>
          <div className="order-container border">
            <h4>Min order</h4>
            <ul className="order-details border">
              <li>
                <p className="title">Privat/Företag</p>
                <div className="detail">
                  <p>{customerData?.company === "" ? "Privat" : "Företag"}</p>
                </div>
              </li>

              {/* Licenser */}
              <li>
                <p className="title">Antal</p>
                <div className="detail">
                  <div>
                    <p><s>{customerData?.subscription?.license?.total} licenser</s></p>
                    <p>{license.quantity} licenser</p>
                  </div>
                </div>
              </li>

              {/* Privat Namn / Kontaktperson */}
              <li>
                <p className="title">
                  {customerData?.company === "" ? "Namn" : "Kontaktperson"}
                </p>
                <div className="detail">
                  <p>{customerData?.firstName} {customerData?.lastName}</p>
                </div>
              </li>

              {/* Address */}
              <li>
                <p className="title">Adress</p>
                <div className="detail">
                  <div>
                    <p>{customerData?.streetAddress}</p>
                    <p>{customerData?.postalAddress}</p>
                  </div>
                </div>
              </li>

            </ul>

            <ul className="order-total border">
              <li>
                <div className="detail">
                  <div></div>
                  <span className="total">
                    <span><h4>{cartRenewal?.yearlyCost} kr</h4>/per år { cartRenewal.yearlyCostVatText }.</span>
                    <span><h4>Att betala: {Math.round(parseInt(cartRenewal.cartTotalPay))}kr </h4>{cartRenewal.cartTotalPayVatText}</span>
                  </span>
                </div>
              </li>
            </ul>
          </div>

          <button disabled={renewalStatus === 'resolving'} type="submit" className="arrow">Genomför köp</button>

          <div className="messages">
            {renewalStatus === 'resolving' &&
              <div className="text-center spinner">
                <p className="mb-1">{license.quantity < customerData?.subscription?.license.total ? 'Nedgraderar' : 'Uppgraderar'} abonnemang</p>
                <ClipLoader size={22} color={"#ffffffbb"} />
              </div>
            }
          </div>
          {renewalStatus === 'error' &&
            <div className="error">
              <p className="mb-1">{license.quantity < customerData?.subscription?.license.total ? 'Nedgradering' : 'Uppgradering'} misslyckades, kolla ditt förvalda betalkort på mina sidor och försök igen.</p>
              <Link to="/user">Till mina sidor ›</Link>
            </div>
          }
        </form>
      }
    </div>
  )
}

const CHECKOUT = gql`
  mutation KbCheckout($input: KbCheckoutInput!) {
    kbCheckout(input: $input) {
      order {
        databaseId
        orderNumber
        total
        lineItems {
          nodes {
            product {
              node {
                name
                databaseId
              }
            }
          }
        }
      }
    }
  }
`
