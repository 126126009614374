import { Link } from 'gatsby'
import React from 'react'
import ArrowRight from '../../images/icons/chevron-right.svg'

export default function UpdateCompleted({ license, customerData }) {
  return (
    <div>
      <h2>Ändring genomförd!</h2>
      <p>Du har nu {license.quantity < customerData?.subscription?.license.total ? 'nedgraderat' : 'uppgraderat'} ditt abonnemang till {license.quantity} licenser.</p>
      <Link to="/user"><button>Mina sidor <img className="arrow" src={ArrowRight} alt="arrow" /></button></Link>
    </div>
  )
}
