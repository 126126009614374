import React, { useState, useEffect } from 'react'
import ReactSlider from 'react-slider'
import { useMutation, gql } from '@apollo/client'
import { useAppState } from '../context.jsx'
import ClipLoader from 'react-spinners/ClipLoader'
import ArrowRight from '../../images/icons/chevron-right.svg'
import dayjs from 'dayjs'


export default function UpdateLicense({ customer, license, setLicense, setStep }) {

  const { cartRenewal, setCartRenewal } = useAppState();
  // const { data } = useProductQuery();
  // const [licenseData, setLicenseData] = useState();
  const [currentLicense, setCurrentLicense] = useState();
  const [status, setStatus] = useState('');
  const [isDragging, setIsDragging] = useState(false)

  useEffect(() => {
    setCurrentLicense(customer?.subscription.priceForUser)
  }, [])

  const [addSubscriptionSwitch] = useMutation(ADD_SUBSCRIPTION_SWITCH, {
    onCompleted: (res) => {
      console.log(res);
      setCartRenewal(res.addSubscriptionSwitchToCart)
      setCurrentLicense(res.addSubscriptionSwitchToCart.yearlyCost)
      setStatus('resolved')
    },
    onError: (err) => {
      console.log(err)
      console.log('There was an error adding your subscription switch');
      setStatus('')
    }
  })

  return (
    <div className="license">
      <form>
        <h3>Uppgradera/nedgradera abonnemanget</h3>
        <div className="licenses">
          <div className="range-wrap d-flex align-items-center">
            <span>1</span>
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="example-thumb"
              trackClassName={`example-track`}
              min={1}
              max={20}
              onBeforeChange={() => setIsDragging(true)}
              onAfterChange={() => {
                if (license.quantity !== customer?.subscription?.license?.total) {
                  setStatus('resolving')
                  addSubscriptionSwitch({
                    variables: { input: { clientMutationId: "123", productVariation: 1907 + license.quantity } }
                  })
                }
                setIsDragging(false)
              }}
              renderThumb={(props, state) => customer && <div {...props}>{state.value}</div>}
              onChange={(props) => setLicense(license => ({ ...license, quantity: props }))}
              defaultValue={license.quantity}
            />
            <span>20</span>
          </div>

        </div>
        <div className="price change">
          <div>
            {customer?.subscription?.license.total !== undefined &&
              <span>
                {license.quantity < customer?.subscription.license.total
                  ? `Nedgradera till ${license.quantity} ` : license.quantity > customer?.subscription.license.total
                    ? `Uppgradera till ${license.quantity} `
                    : `Du har för tillfället ${customer?.subscription?.license?.total} `}
                licenser
              </span>
            }
          </div>
          <div><span>
          {cartRenewal
            ? Math.round(parseInt(cartRenewal.cartTotalUser)) + 'kr ' + cartRenewal.cartTotalUserVatText
            : '0kr'
          }
          </span></div>

          <div><span>Fram till den {`${dayjs(customer?.subscription?.nextPaymentDate).get('date').toString()}/${(dayjs(customer?.subscription?.nextPaymentDate).get('month') + 1).toString()}-${dayjs(customer?.subscription?.nextPaymentDate).get('year').toString().substr(-2)}`}</span></div>
          <p>Därefter&nbsp;
          {
            license.quantity !== customer?.subscription?.license?.total // If amount of licenses has been changed
            ?
              cartRenewal && !isDragging ? Math.round(parseInt(cartRenewal?.yearlyCost)) : currentLicense
            : 
              customer?.subscription.priceForUser
          }
          kr/år { cartRenewal?.yearlyCostVatText }</p>
        </div>

        <button onClick={(e) => { e.preventDefault(); setStep(2) }} className="arrow" disabled={!customer || license.quantity === customer?.subscription?.license?.total || status === 'resolving'}>Gå vidare<img className="arrow" src={ArrowRight} alt="arrow" /></button>

        <div className="messages">
          {status === 'resolving' &&
            <div className="text-center spinner">
              <p className="mb-1">Uppdaterar pris</p>
              <ClipLoader size={22} color={"#ffffffbb"} />
            </div>
          }
        </div>
      </form>
    </div>
  )
}

const ADD_SUBSCRIPTION_SWITCH = gql`
  mutation($input: AddSubscriptionSwitchToCartInput!) {
    addSubscriptionSwitchToCart(input: $input) {
      clientMutationId
      cartTotalUser
      cartTotalPay
      yearlyCost
      cartTotalUserVatText
      cartTotalPayVatText
      yearlyCostVatText
    }
  }
`
